<template>
  <div id="app">
    <!-- 右侧联系我们 -->

    <div class="contact-card" v-show="erweimaShow == 1">
      <p class="jingli">扫码添加客户经理</p>
      <p class="fangan">获取更多方案</p>
      <img src="./assets/img/电话.png" alt="二维码" class="qr-code" />
    </div>
    <div class="fixed-contact">
      <div class="contact-phone" v-show="erweimaShow == 2">
        <p class="phonename">{{ phonename }}</p>
        <p class="phonenum">{{ phonenum }}</p>
      </div>
      <div class="contact-actions">
        <img src="./assets/img/合作咨询@2x.png" alt="" @click="openerweima()" />
        <div class="hezuo">
          <p class="hezuozi">合作咨询</p>
        </div>
        <div class="contact-options">
          <div class="option" @click="changedian()">
            <img src="./assets/img/电话@2x.png" alt="" />
            <p>电话</p>
          </div>
          <div class="option" @click="changeyou()">
            <img src="./assets/img/邮箱@2x.png" alt="" />
            <p>邮箱</p>
          </div>
        </div>
      </div>

      <div class="optiond" @click="goBack">
        <img src="./assets/img/返回顶部@2x.png" alt="" />
      </div>
    </div>

    <!-- 顶部区域 -->
    <header class="header">
      <div class="header-content">
        <div class="header-descr">
          <p>AI Agent</p>
          <p>企业发展的超强赋能引擎</p>
        </div>
        <p>
          Agent思考规划，对话流逻辑支持工作流编排，实现更稳定的企业级多轮对话体验
        </p>
      </div>
    </header>

    <!-- 专家模型区域 -->
    <section class="expert-models">
      <div class="expert-content">
        <div class="expert-title">
          <p>内置行业专家模型</p>
          <p class="sys">
            专家模型是针对行业或领域，通过结合行业数据和通用大模型进行微调，以提高高度专业化和精准服务的人工智能模型
          </p>
        </div>

        <div class="card-container">
          <div
            v-for="(card, index) in card"
            :key="index"
            class="card"
            :class="{ active: activeCard === index }"
            @mouseover="setActiveCard(index)"
            @mouseleave="clearActiveCard"
          >
            <img
              :src="activeCard === index ? card.hoverImageUrl : card.imageUrl"
              alt="Card image"
              class="card-image"
            />
            <h3 class="card-title">{{ card.title }}</h3>
            <ul class="card-tabs">
              <li
                v-for="(tab, tabIndex) in card.tabs"
                :key="tabIndex"
                class="tab"
              >
                {{ tab }}
              </li>
            </ul>
            <p class="card-description">{{ card.description }}</p>
          </div>
        </div>
        <div class="card-coner">
          <div
            v-for="(card, index) in cards"
            :key="index"
            class="cards"
            :class="{ active: activeCards === index }"
            @mouseover="setActiveCards(index)"
            @mouseleave="clearActiveCards"
          >
            <img
              :src="activeCards === index ? card.hoverImageUrl : card.imageUrl"
              alt="Card image"
              class="cards-image"
            />
            <h3 class="cards-title">{{ card.title }}</h3>
            <ul class="cards-tabs">
              <li
                v-for="(tab, tabIndex) in card.tabs"
                :key="tabIndex"
                class="tabs"
              >
                {{ tab }}
              </li>
            </ul>
            <p class="cards-description">{{ card.description }}</p>
          </div>
        </div>
        <div class="more">
          <button @click="openwe()">了解更多专家模型</button>
        </div>
      </div>
    </section>

    <!-- 模型调优区域 -->
    <section class="model-services">
      <div class="services-title">
        <p>模型调优全服务</p>
        <p class="sys">
          支持企业通过上传自有数据，完成模型微调的全流程，从而打造深度适配其业务场景和行业特点的专家模型
        </p>
      </div>
      <div class="services-content">
        <div class="left">
          <img src="./assets/img/左边图@2x.png" alt="" />
        </div>
        <div class="right">
          <div class="card-coner">
            <div
              v-for="(card, index) in model"
              :key="index"
              class="cards"
              :class="{ active: activemodel === index }"
              @mouseover="setActivemodel(index)"
              @mouseleave="clearActivemodel"
            >
              <img
                :src="
                  activemodel === index ? card.hoverImageUrl : card.imageUrl
                "
                alt="Card image"
                class="cards-image"
              />
              <h3 class="cards-title">{{ card.title }}</h3>
              <p class="cards-description">{{ card.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 应用开发区域 -->
    <section class="agent-development">
      <div class="expert-content">
        <div class="expert-title">
          <p>Agent应用开发</p>
          <p class="sys">
            企业级Agent和RAG应用开发平台，通过提供定制化的智能解决方案，助力企业实现生产力的跨越式升级
          </p>
        </div>
        <div class="conbox">
          <div class="contond">
            <div class="image-item1">
              <p class="image-text">丰富工具库</p>
              <p class="image-text1">
                工具库是一个集成了多种功能工具集合，这些工具可能包括资讯阅读、旅游出行、效率办公、图片理解等AP|和多模态模型，以扩展Agent的功能和应用范国。
              </p>
            </div>
            <div class="pro-item">
              <div class="xian"></div>
              <div class="card-pro">
                <div
                  v-for="(card, index) in agent1"
                  :key="index"
                  class="feature"
                >
                  <img
                    :src="
                      activemodel === index ? card.hoverImageUrl : card.imageUrl
                    "
                    alt="Card image"
                    class="feature-image"
                  />
                  <h3 class="feature-title">{{ card.title }}</h3>
                  <p class="feature-description">{{ card.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="contond">
            <div class="image-item2">
              <p class="image-text">企业级知识库(RAG)</p>
              <p class="image-text1">
                知识库是Agent的核心，存储了大量结构化和非结构化的数据，包括事实、规则、概念等，为Agent提供决策支持和背景知识。
              </p>
            </div>
            <div class="pro-item">
              <div class="xian"></div>
              <div class="card-pro">
                <div
                  v-for="(card, index) in agent2"
                  :key="index"
                  class="feature"
                >
                  <img
                    :src="
                      activemodel === index ? card.hoverImageUrl : card.imageUrl
                    "
                    alt="Card image"
                    class="feature-image"
                  />
                  <h3 class="feature-title">{{ card.title }}</h3>
                  <p class="feature-description">{{ card.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="contond">
            <div class="image-item3">
              <p class="image-text">工作流编排(Workfow)</p>
              <p class="image-text1">
                工作流编排功能允许开发者定义和管理Agent执行任务的顺序和逻辑，包括任务的触发、执行、条件分支和并行处理等。
              </p>
            </div>
            <div class="pro-item">
              <div class="xian"></div>
              <div class="card-pro">
                <div
                  v-for="(card, index) in agent3"
                  :key="index"
                  class="feature"
                >
                  <img
                    :src="
                      activemodel === index ? card.hoverImageUrl : card.imageUrl
                    "
                    alt="Card image"
                    class="feature-image"
                  />
                  <h3 class="feature-title">{{ card.title }}</h3>
                  <p class="feature-description">{{ card.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="agent-title">
        <p>工作流就是生产线</p>
      </div>
      <div class="agent-end">
        <div class="nav-container">
          <ul class="nav-list">
            <li
              v-for="(item, index) in navItems"
              :key="index"
              class="nav-item"
              :class="{ active: activeIndex === index }"
              @click="setActive(index)"
            >
              <a class="nav-link">{{ item }}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- 工作流生产线 -->
    <section class="lication">
      <div class="lication-contont">
        <div class="lica-conter">
          <div class="lication-left">
            <p>{{ workname }}</p>
            <p class="excel">
              {{ workcontent }}
            </p>
          </div>
          <div class="location-right">
            <img
              src="./assets/img/园区助手@2x.png"
              alt=""
              :class="{ enlarged: isEnlarged }"
              @click="toggleImageSize"
              v-show="this.activeIndex == 0"
            />
            <img
              src="./assets/img/智能客服@2x.png"
              alt=""
              :class="{ enlarged: isEnlarged }"
              @click="toggleImageSize"
              v-show="this.activeIndex == 1"
            />
            <img
              src="./assets/img/HR助手@2x.png"
              alt=""
              :class="{ enlarged: isEnlarged }"
              @click="toggleImageSize"
              v-show="this.activeIndex == 2"
            />
            <img
              src="./assets/img/活动策划@2x.png"
              alt=""
              :class="{ enlarged: isEnlarged }"
              @click="toggleImageSize"
              v-show="this.activeIndex == 3"
            />
            <img
              src="./assets/img/旅行小蜜@2x.png"
              alt=""
              :class="{ enlarged: isEnlarged }"
              @click="toggleImageSize"
              v-show="this.activeIndex == 4"
            />
          </div>
        </div>
      </div>
      <div class="lication-center">
        <img src="./assets/img/应用装饰-左@2x.png" alt="" />
        <p>应用</p>
        <img src="./assets/img/应用装饰-右@2x.png" alt="" />
      </div>
      <div class="location-down">
        <!-- <div
          class="image-gallery"
          data-direction="reverse"
          style="--list-item-number: 22; --duration: 16s"
        >
          <div class="image-item" v-for="(model, index) in models" :key="index">
            <img :src="model.imageUrl" :alt="model.title" />
            <p>{{ model.title }}</p>
          </div>
        </div> -->

        <!-- <vue-j-scroll
          class="scroll-gallery"
          :data="models"
          :steep="0.5"
          scroll-direction="left"
          :is-roller="true"
          :roller-scroll-distance="50"
        >
          <div class="image-item" v-for="(model, index) in models" :key="index">
            <img :src="model.imageUrl" :alt="model.title" />
            <p>{{ model.title }}</p>
          </div>
        </vue-j-scroll> -->
        <div
          class="mylist"
          data-direction="reverse"
          style="--list-item-number: 11; --duration: 12s"
        >
          <div class="list-item">
            <img src="./assets/img/图片诗意描述器@2x.png" alt="" />
            <p>图片诗意描述器</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/爱因斯坦@2x.png" alt="" />
            <p>爱因斯坦</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/试题信息抽取@2x.png" alt="" />
            <p>试题信息抽取</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/新闻咨询助手@2x.png" alt="" />
            <p>新闻咨询助手</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/电商售前客服@2x.png" alt="" />
            <p>电商售前客服</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/电商售后客服@2x.png" alt="" />
            <p>电商售后客服</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/中差评干预@2x.png" alt="" />
            <p>中差评干预</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/企业HR助手@2x.png" alt="" />
            <p>企业HR助手</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/能源策略助手@2x.png" alt="" />
            <p>能源策略助手</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/小学生作业辅导@2x.png" alt="" />
            <p>小学生作业辅导</p>
          </div>
          <div class="list-item" style="background-size: cover">
            <img src="./assets/img/大学英语助手@2x.png" alt="" />
            <p>大学英语助手</p>
          </div>
        </div>
      </div>
    </section>

    <!-- 打造高智商 -->
    <div class="clever">
      <div class="clever-title">
        <p>打造高智商、高颜值数智人</p>
        <p class="sys">
          数智人平台是一个集成先进人工智能技术的虚拟助手系统，旨在为企业和个人提供智能化服务，通过模拟人类的智慧和交互能力，提升决策效率和用户体验。
        </p>
      </div>
      <div class="clever-content">
        <div class="button-list">
          <div
            v-for="(button, index) in buttons"
            :key="index"
            class="button-item"
            @click="setActiveIndex(index)"
            @mouseover="handleMouseOver(index)"
            @mouseout="handleMouseOut(index)"
            :class="{ active: activeButtonIndex === index }"
          >
            <!-- 图片 -->
            <img
              :src="
                activeButtonIndex === index
                  ? button.hoverImage
                  : button.defaultImage
              "
              :alt="button.label"
              class="button-image"
            />
            <!-- 按钮文字 -->
            <p class="button-label">{{ button.label }}</p>
          </div>
        </div>
        <div class="clever-center">
          <div class="clever-left">
            <div class="card-pro">
              <p class="come">{{ title }}</p>
              <div
                v-for="(card, index) in clever1"
                :key="index"
                class="feature"
              >
                <img
                  :src="card.imageUrl"
                  alt="Card image"
                  class="feature-image"
                />
                <h3 class="feature-title">{{ card.title }}</h3>
              </div>
            </div>
            <div class="testmore">
              <button @click="openwe()">了解更多</button>
            </div>
          </div>
          <div
            class="clever-right"
            v-for="(model, index) in buttons"
            :key="index"
            v-show="activeButtonIndex === index"
          >
            <img :src="model.imageploUrl" alt="" class="main-image" />
            <img :src="model.hoverImageUrl" alt="" class="overlay-image" />
          </div>
        </div>
      </div>
    </div>

    <!-- 模型调优区域 -->
    <section class="deploy-services">
      <div class="deploy-title">
        <p>模型调优全服务</p>
      </div>

      <div class="deploy-center">
        <div class="deployment-container">
          <div class="deployment-item private">
            <div class="content">
              <h3>私有化部署</h3>
              <p>
                对数据安全性和隐私性要求极高的企业，如金融、医疗政府机构等，它们需要确保数据不离开自己的数据中心
              </p>
              <div class="cen-top">
                <img src="./assets/img/安全性@2x.png" alt="" />
                <p class="nun">安全性</p>
              </div>
              <p class="sepa">
                数据存储在企业自己的服务器上，更容易控制数据访问和保护。
              </p>
              <div class="cen-top">
                <img src="./assets/img/定制性@2x.png" alt="" />
                <p class="nun">定制性</p>
              </div>
              <p class="sepa">可以根据企业的具体需求进行定制化开发和调整。</p>
              <div class="cen-top">
                <img src="./assets/img/合规性@2x.png" alt="" />
                <p class="nun">合规性</p>
              </div>
              <p class="sepa">更容易满足行业特定的合规和法规要求。</p>
              <div class="contact">
                <button @click="openwe()">联系我们</button>
              </div>
            </div>
          </div>
          <div class="deployment-item public">
            <div class="content">
              <h3>公有云部署</h3>
              <p>
                追求灵活性和可扩展性的企业，尤其是初创公司和中小企业，它们需要快速部暑和按需扩展资源。
              </p>
              <div class="cen-top">
                <img src="./assets/img/成本效益@2x.png" alt="" />
                <p class="nun">成本效益</p>
              </div>
              <p class="sepa">无需前期大量投资硬件，按使用量付费。</p>
              <div class="cen-top">
                <img src="./assets/img/灵活性@2x.png" alt="" />
                <p class="nun">灵活性</p>
              </div>
              <p class="sepa">根据业务需求快速调整资源，容易扩展或缩减服务。</p>
              <div class="cen-top">
                <img src="./assets/img/维护简便@2x.png" alt="" />
                <p class="nun">维护简便</p>
              </div>
              <p class="sepa">云服务商负责基础设施的维护和升级。</p>
              <div class="contact">
                <button @click="openwe()">联系我们</button>
              </div>
            </div>
          </div>
          <div class="deployment-item integrated">
            <div class="content">
              <h3>软硬一体部署</h3>
              <p>
                需要快速部署和特定硬件优化的企业，如边缘计算场景，或者需要特定硬件支持的AI应用。
              </p>

              <div class="cen-top">
                <img src="./assets/img/性能优化@2x.png" alt="" />
                <p class="nun">性能优化</p>
              </div>
              <p class="sepa">硬件可以根据软件需求进行优化，提高运行效率。</p>
              <div class="cen-top">
                <img src="./assets/img/快速部署@2x.png" alt="" />
                <p class="nun">快速部署</p>
              </div>
              <p class="sepa">预装的软件和硬件可以快速启动和运行。</p>
              <div class="cen-top">
                <img src="./assets/img/一体化管理@2x.png" alt="" />
                <p class="nun">一体化管理</p>
              </div>
              <p class="sepa">软硬件集成管理，简化维护和升级流程。</p>
              <div class="contact">
                <button @click="openwe()">联系我们</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- 底部区域 -->
    <footer class="footer">
      <div class="foot">
        <div class="left">
          <p>联系我们</p>
          <div class="footer-content">
            <p>邮箱:499366580@qq.com</p>
            <p>地址:湖北省武汉市洪山区光谷大道辅路与鲁阳东街交叉口光谷8号2期</p>
            <p>电话:188 0719 7223</p>
          </div>
        </div>
        <div class="right">
          <p>联系我们</p>
          <div class="footer-contant">
            <img src="./assets/img/电话.png" alt="" />
            <p>商家服务号</p>
          </div>
        </div>
      </div>
      <div class="xian"></div>
      <div class="pany">
        ©2025 大渊科技（武汉）有限公司
        <span @click="handleClick"> 鄂ICP备2021001893号-4 </span>
      </div>
    </footer>
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import "@/assets/font/font.css";

export default {
  name: "App",
  components: {
    // HelloWorld,
  },
  data() {
    return {
      isEnlarged: false, // 控制图片是否放大
      workname:
        "将企业的数据源、数据库、日志文件和报告整合到 AI 工作流中，自动生成数据分析报告",
      workcontent:
        "通过 AI 工作流，企业可以自动化数据清洗、数据挖掘和趋势预测，实现实时分析决策，提升数据驱动的运营效率，帮助管理层做出更精准的战略决策",
      phonename: "电话",
      phonenum: "188 0719 7223",
      erweimaShow: null,
      topShow: true,
      title: "面向政企行业，打造智能政务助手",
      activeName: "second",
      activeCard: null, // 当前鼠标移入的卡片索引
      activeCards: null, // 当前鼠标移入的卡片索引
      activemodel: null, // 当前鼠标移入的卡片索引
      activeIndex: 0, // 默认第一个选项高亮
      activeButtonIndex: 0, // 改为 activeButtonIndex
      navItems: [
        "智能数据分析工作流",
        "智能客服工作流",
        "智能HR员工工作流",
        "智能营销活动工作流",
        "智能旅行攻略",
      ],
      card: [
        {
          title: "农业专家模型",
          tabs: ["精准农业", "供应链优化"],
          imageUrl: require("./assets/img/农业-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/农业-悬停@2x.png"),
          description:
            "用于作物监测、病虫害识别、土壤分析和气候预测。优化农产品的物流和供应链管理。",
        },
        {
          title: "金融专家模型",
          tabs: ["风险评估", "投资分析"],
          imageUrl: require("./assets/img/金蒜-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/金蒜-悬停@2x.png"),
          description:
            "用于信贷评估、市场风险预测和数据检测。提供股票市场分析、投资组合建议和趋势预测。",
        },
        {
          title: "医疗健康专家模型",
          tabs: ["疾病诊断", "医学影像分析", "药物研发"],
          imageUrl: require("./assets/img/医疗-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/医疗-悬停@2x.png"),
          description:
            "用于作物监测、病虫害识别、土壤分析和气候预测。优化农产品的物流和供应链管理。",
        },
      ],
      cards: [
        {
          title: "教育专家模型",
          tabs: ["个性化学习", "智能评估", "教育资源推荐"],
          imageUrl: require("./assets/img/教育-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/教育-悬停@2x.png"),
          description:
            "根据学生的学习习惯和能力提供定制化教学内容。自动化评估学生的作业和考试，提供反馈和改进建议。为教师和学生推荐适合的教学资源和工具。",
        },
        {
          title: "法律专家模型",
          tabs: ["案例分析", "合同审查", "合规性检查"],
          imageUrl: require("./assets/img/法律-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/法律-悬停@2x.png"),
          description:
            "快速检索相关法律案例和文献，辅助法律研究。自动检查合同条款，降低风险。确保企业操作符合法律法规要求。",
        },
      ],
      model: [
        {
          title: "数据集管理",
          imageUrl: require("./assets/img/数据集管理-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/数据集管理-选中@2x.png"),
          description:
            "帮助企业整理和处理用于训练模型的数据，确保数据的质量和可用性。",
        },
        {
          title: "模型训练",
          imageUrl: require("./assets/img/模型推理-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/模型推理-选中@2x.png"),
          description:
            "提供多种训练方法(如Prompt、LORA、SFT)，让企业能够根据需求调整模型，以适应特定的业务场景。",
        },
        {
          title: "模型推理",
          imageUrl: require("./assets/img/模型评估-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/模型评估-选中@2x.png"),
          description:
            "提供多种训练方法(如Prompt、LORA、SFT)，让企业能够根据需求调整模型，以适应特定的业务场景。",
        },
        {
          title: "模型评估",
          imageUrl: require("./assets/img/模型调优-默认@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/模型调优-选中@2x.png"),
          description:
            "通过关键指标衡量模型表现，帮助企业了解模型效果并进行必要的优化。",
        },
      ],
      agent1: [
        {
          title: "功能丰富",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description: "提供多种预构建的插件，满足不同业务场景的需求。",
        },
        {
          title: "快速集成",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description:
            "开发者可以直接集成插件，加速开发过程，无需从头开发所有功能。",
        },
        {
          title: "灵活性",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description: "插件库允许开发者根据项目需求灵活选择和组合功能模块。",
        },
      ],
      agent2: [
        {
          title: "智能支持",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description:
            "使Agent能够访问和理解大量的信息，提高其处理复杂查询的能力。",
        },
        {
          title: "持续学习",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description: "知识库可以不断更新，使Agent能够适应新信息和变化。",
        },
        {
          title: "个性化服务",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description:
            "基于知识库的内容，Agent能够提供更加个性化和精准的服务。",
        },
      ],
      agent3: [
        {
          title: "自动化",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description: "自动化复杂的业务流程，提高效率和准确性。",
        },
        {
          title: "可定制化",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description:
            "根据具体的业务需求定制工作流程，提高Agent的适应性和灵活性。",
        },
        {
          title: "监控与优化",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          description:
            "通过监控工作流的执行情况，开发者可以优化流程，提升性能。",
        },
      ],
      models: [
        {
          title: "图片诗意描述器",
          imageUrl: require("./assets/img/图片诗意描述器@2x.png"), // 添加图片路径
        },
        {
          title: "爱因斯坦",
          imageUrl: require("./assets/img/爱因斯坦@2x.png"), // 添加图片路径
        },
        {
          title: "试题信息抽取",
          imageUrl: require("./assets/img/试题信息抽取@2x.png"), // 添加图片路径
        },
        {
          title: "新闻咨询助手",
          imageUrl: require("./assets/img/新闻咨询助手@2x.png"), // 添加图片路径
        },
        {
          title: "电商售前客服",
          imageUrl: require("./assets/img/电商售前客服@2x.png"), // 添加图片路径
        },
        {
          title: "电商售后客服",
          imageUrl: require("./assets/img/电商售后客服@2x.png"), // 添加图片路径
        },
        {
          title: "中差评干预",
          imageUrl: require("./assets/img/中差评干预@2x.png"), // 添加图片路径
        },
        {
          title: "企业HR助手",
          imageUrl: require("./assets/img/企业HR助手@2x.png"), // 添加图片路径
        },
        {
          title: "能源策略助手",
          imageUrl: require("./assets/img/能源策略助手@2x.png"), // 添加图片路径
        },
        {
          title: "小学生作业辅导",
          imageUrl: require("./assets/img/小学生作业辅导@2x.png"), // 添加图片路径
        },
        {
          title: "大学英语助手",
          imageUrl: require("./assets/img/大学英语助手@2x.png"), // 添加图片路径
        },
      ],
      buttons: [
        {
          label: "政企行业",
          action: "action1",
          defaultImage: require("./assets/img/政企行业-默认@2x.png"), // 默认图片
          hoverImage: require("./assets/img/政企行业-选中@2x.png"), // 移入时的图片
          imageploUrl: require("./assets/img/01 政企行业@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/01-政企行业数智人@2x.png"),
        },
        {
          label: "展会餐厅",
          action: "action2",
          defaultImage: require("./assets/img/展会餐厅-默认@2x.png"),
          hoverImage: require("./assets/img/展会餐厅-选中@2x.png"),
          imageploUrl: require("./assets/img/02-展会餐厅@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/02-展会餐厅数智人@2x.png"),
        },
        {
          label: "金融行业",
          action: "action3",
          defaultImage: require("./assets/img/金融行业-默认@2x.png"),
          hoverImage: require("./assets/img/金融行业-选中@2x.png"),
          imageploUrl: require("./assets/img/03-金融行业@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/03-金融行业数智人@2x.png"),
        },
        {
          label: "文旅行业",
          action: "action4",
          defaultImage: require("./assets/img/文旅行业-默认@2x.png"),
          hoverImage: require("./assets/img/文旅行业-选中@2x.png"),
          imageploUrl: require("./assets/img/景区背景图@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/文旅行业数智人@2x.png"),
        },
        {
          label: "企业客服",
          action: "action5",
          defaultImage: require("./assets/img/企业客服-默认@2x.png"),
          hoverImage: require("./assets/img/企业客服-选中@2x.png"),
          imageploUrl: require("./assets/img/04 企业客服@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/04-企业客服数智人@2x.png"),
        },
        {
          label: "直播电商",
          action: "action6",
          defaultImage: require("./assets/img/直播电商-默认@2x.png"),
          hoverImage: require("./assets/img/直播电商-选中@2x.png"),
          imageploUrl: require("./assets/img/05-直播电商@2x.png"), // 添加图片路径
          hoverImageUrl: require("./assets/img/05-直播电商数智人@2x.png"),
        },
      ],
      clever1: [
        {
          title: "提供政策解读和咨询服务 ",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
        },
        {
          title: "实现政务流程自动化，提高办事效率",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
        },
        {
          title: "多渠道接入：网站、社交媒体、自助服务终端",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
        },
        {
          title: "提升政府透明度和民众满意度",
          imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
        },
      ],
    };
  },
  methods: {
    handleClick() {
      window.open("https://beian.miit.gov.cn", "_blank");
    },
    toggleImageSize() {
      this.isEnlarged = !this.isEnlarged; // 切换放大状态
    },
    openwe() {
      this.erweimaShow = 1;
    },
    changedian() {
      this.erweimaShow = 2;
      this.phonename = "电话";
      this.phonenum = "188 0719 7223";
    },
    changeyou() {
      this.erweimaShow = 2;
      this.phonename = "邮箱";
      this.phonenum = "499366580@qq.com";
    },
    openerweima() {
      this.erweimaShow = !this.erweimaShow;
    },
    setActiveCard(index) {
      this.activeCard = index; // 设置当前激活卡片索引
    },

    // 滚动时判断是否显示返回顶部按钮
    checkScrollPosition() {
      if (window.scrollY > 500) {
        this.topShow = true; // 显示返回顶部按钮
      } else {
        this.topShow = false; // 隐藏返回顶部按钮
      }
    },

    // 返回页面顶部的方法
    goBack() {
      // 使用 window.scrollTo() 方法平滑滚动到顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
    clearActiveCard() {
      this.activeCard = null; // 清除激活状态
    },
    setActiveCards(index) {
      this.activeCards = index; // 设置当前激活卡片索引
    },
    clearActiveCards() {
      this.activeCards = null; // 清除激活状态
    },
    setActivemodel(index) {
      this.activemodel = index; // 设置当前激活卡片索引
    },
    clearActivemodel() {
      this.activemodel = null; // 清除激活状态
    },
    setActive(index) {
      this.activeIndex = index; // 鼠标移入时设置高亮的选项索引
      if (this.activeIndex == 0) {
        (this.workname =
          "将企业的数据源、数据库、日志文件和报告整合到 AI 工作流中，自动生成数据分析报告"),
          (this.workcontent =
            "通过 AI 工作流，企业可以自动化数据清洗、数据挖掘和趋势预测，实现实时分析决策，提升数据驱动的运营效率，帮助管理层做出更精准的战略决策");
      } else if (this.activeIndex == 1) {
        (this.workname = "通过 AI 工作流，自动处理客户咨询、问题解答和投诉"),
          (this.workcontent =
            "平台整合企业的知识库、常见问题和客户反馈，智能体能够快速识别客户需求并提供准确答案。随着对话的进行，智能客服不断优化应答质量，提升客户满意度，降低人工客服成本，提高服务效率。");
      } else if (this.activeIndex == 2) {
        (this.workname =
          "通过 AI 工作流，自动化处理员工招聘、入职、培训、绩效评估等人力资源管理环节"),
          (this.workcontent =
            "智能HR员工根据职位需求筛选简历、安排面试、提供培训并跟踪员工发展。通过整合员工数据和绩效反馈，平台优化招聘和人才发展流程，提升管理效率，减少人工工作量，确保精准人才匹配和持续成长。");
      } else if (this.activeIndex == 3) {
        (this.workname =
          "利用 AI 工作流自动化管理营销活动的各个环节，包括市场调研、广告投放、活动跟踪与优化等"),
          (this.workcontent =
            "通过整合多渠道数据，企业能够实时调整营销策略，实现精准营销和客户细分，提升市场活动的ROI。");
      } else if (this.activeIndex == 4) {
        (this.workname = "通过 AI 工作流，自动化生成个性化旅行攻略"),
          (this.workcontent =
            "智能体整合旅游信息和用户偏好，自动推荐最佳旅行路线和活动安排。通过 AI 工作流，企业提供精准定制的旅行建议，提升客户体验，减少规划时间，增加服务满意度。");
      }
    },
    handleMouseOver(index) {
      if (index == 0) {
        this.title = "面向政企行业，打造智能政务助手";
        this.clever1 = [
          {
            title: "提供政策解读和咨询服务 ",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "实现政务流程自动化，提高办事效率",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "多渠道接入：网站、社交媒体、自助服务终端",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "提升政府透明度和民众满意度",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
        ];
      } else if (index == 1) {
        this.title = "面向展会餐厅行业，打造智能服务大使";
        this.clever1 = [
          {
            title: "提供展会信息和餐饮推荐 ",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "实现实时互动和客户反馈收集",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "多设备支持：智能平板、智能机器人、移动应用",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "提升展会服务效率和餐饮体验",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
        ];
      } else if (index == 2) {
        this.title = "面向金融行业，打造智能金融顾问";
        this.clever1 = [
          {
            title: "提供个性化金融产品推荐 ",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "实现快速交易和资产管理",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "多平台服务：网上银行、移动银行、自助终端",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "提高金融服务安全性和便捷性",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
        ];
      } else if (index == 3) {
        this.title = "面向文旅行业，打造数字虚拟IP";
        this.clever1 = [
          {
            title: "景区专属代言人∨",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "提供景区介绍及导览服务",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "多终端:一体机、移动端等硬件",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "优化景区服务效率，提升旅客体验",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
        ];
      } else if (index == 4) {
        this.title = "面向企业客服，打造智能客服助手";
        this.clever1 = [
          {
            title: "提供24/7不间断的客户咨询服务",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "实现复杂问题的智能分诊和处理",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "多渠道接入：电话、在线聊天、社交媒体",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "提升客户服务效率和解决问题的准确性",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
        ];
      } else if (index == 5) {
        this.title = "面向直播电商行业，打造智能直播助手";
        this.clever1 = [
          {
            title: "提供产品展示和实时互动功能",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "实现观众行为分析和销售预测",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "多设备支持：直播软件、移动应用、电商平台",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
          {
            title: "提升直播互动性和销售转化率",
            imageUrl: require("./assets/img/编组 37@2x.png"), // 添加图片路径
          },
        ];
      }
      setTimeout(() => {
        this.activeButtonIndex = index; // 切换图片索引
      }, 250); // 延迟300ms后切换图片
    },
    handleMouseOut(index) {
      setTimeout(() => {
        this.activeButtonIndex = index; // 切换图片索引
      }, 250); // 延迟300ms后切换图片
    },
    setActiveIndex(index) {
      setTimeout(() => {
        this.activeButtonIndex = index; // 切换图片索引
      }, 350); // 延迟300ms后切换图片
    },
  },
  mounted() {
    // 初始化每个按钮的 currentImageUrl 为默认图片
    this.buttons.forEach((button) => {
      button.currentImageUrl = button.imageUrl;
    });

    // 在组件挂载时监听滚动事件，判断何时显示返回顶部按钮
    window.addEventListener("scroll", this.checkScrollPosition);
  },

  beforeDestroy() {
    // 组件销毁前移除滚动事件监听
    window.removeEventListener("scroll", this.checkScrollPosition);
  },
};
</script>

<!-- 移动 -->
<style>
@media screen and (max-width: 850px) {
  #app {
    font-size: 20px;
  }
  /* 固定联系框整体样式 */
  .fixed-contact {
    display: none !important;
  }
  .deploy-services {
    display: none !important;
  }
  /* 顶部区域 */
  .header {
    padding: 40px !important;
  }

  .header-descr p:first-child {
    text-align: left;
    font-size: 18px !important;
    margin-bottom: 10px;
  }

  .header-descr p:nth-child(2) {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .header p {
    width: 400px;
    font-size: 10px !important;
  }
  /* <!-- 专家模型区域 --> */
  /* 专家模型整体区域 */
  .expert-models {
    padding: 15px;
    background-color: #f9f9f9; /* 可选：轻背景提升层次感 */
  }

  .expert-content {
    display: flex;
    flex-direction: column;
    align-items: center; /* 内容居中对齐 */
  }

  /* 标题部分 */
  .expert-title p:first-child {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }

  .expert-title .sys {
    font-size: 12px;
    text-align: center;
    line-height: 1.5;
    margin-bottom: 15px;
    color: #666; /* 字体颜色稍淡 */
  }

  /* 卡片容器 */
  .card-container {
    display: flex;
    flex-direction: row;
    gap: 15px; /* 卡片之间间距 */
    width: 100%;
  }

  /* 单个卡片 */
  .card {
    width: 100% !important; /* 卡片宽度占满容器 */
    padding: 10px 25px 0 25px !important;
    background: #ffffff; /* 白色背景 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻阴影 */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .card-image {
    width: 5.9375vw !important; /* 缩小图片大小适配手机 */
    height: 5.9375vw !important;
    object-fit: cover;
    border-radius: 50%; /* 可选：使图片圆形 */
    margin-top: 5px;
  }

  .card-title {
    padding: 31px 0 0 24px !important;
    font-size: 18px !important;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }

  .card-tabs .tab {
    font-size: 12px;
    margin: 3px 0;
    color: #555;
  }

  .card-description {
    height: 100px;
    font-size: 12px;
    font-size: 16px !important;
    line-height: 6.354167vw !important;
    color: #666;
    margin-top: 8px;
  }

  /* 卡片交互样式（鼠标或触摸） */
  .card.active {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }

  /* 第二部分的卡片区域 */
  .card-coner {
    display: flex;
    flex-direction: column;
    gap: 15px; /* 卡片间距 */
    width: 100%;
  }

  .cards {
    width: 100% !important; /* 卡片宽度占满容器 */
    padding: 10px;
    background: #ffffff; /* 白色背景 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻阴影 */
    border-radius: 8px;
    text-align: center;
  }

  .cards-image {
    width: 5.9375vw !important; /* 缩小图片大小适配手机 */
    height: 5.9375vw !important;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 5px;
  }

  .cards-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }

  .cards-tabs .tabs {
    font-size: 12px;
    margin: 3px 0;
    color: #555;
  }

  /* 按钮样式 */
  .more button {
    font-size: 14px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }

  .more button:hover {
    background-color: #0056b3; /* 按钮悬停颜色 */
    transition: background-color 0.2s ease;
  }
  /* <!-- 模型调优区域 --> */
  .model-services {
    padding: 15px;
    background-color: #f9f9f9; /* 可选：浅背景色提升层次 */
  }

  /* 标题部分 */
  .services-title {
    padding: 0 !important;
    text-align: center;
    margin-bottom: 15px;
  }

  .services-title p:first-child {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
  }

  .services-title .sys {
    font-size: 12px;
    color: #666;
    line-height: 6.041667vw !important;
  }

  /* 内容区域 */
  .services-content {
    padding: 0 !important;
    display: flex;
    flex-direction: column; /* 改为纵向布局 */
    align-items: center; /* 居中对齐 */
  }

  /* 左侧图片区域 */
  .services-content .left {
    width: 100%; /* 图片区域宽度占满 */
    text-align: center; /* 居中显示 */
    margin-bottom: 15px; /* 下方间距 */
  }

  .services-content .left img {
    width: 100%; /* 图片宽度自适应容器 */
    max-width: 300px; /* 限制最大宽度 */
    height: auto;
    border-radius: 8px; /* 可选：圆角效果 */
  }

  /* 右侧卡片区域 */
  .services-content .right {
    width: 100%;
  }

  .services-content .card-coner {
    display: flex;
    flex-direction: column; /* 卡片垂直排列 */
    gap: 15px; /* 卡片之间的间距 */
  }

  /* 单个卡片 */
  .cards {
    padding: 15px;
    background: #ffffff; /* 白色背景 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 轻微阴影 */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center; /* 居中对齐内容 */
    text-align: center;
  }

  .cards-image {
    width: 4.9375vw !important; /* 缩小图片以适应手机屏幕 */
    height: 4.9375vw !important;
    object-fit: cover;
    margin-bottom: 10px;
    border-radius: 50%; /* 图片圆形化 */
  }

  .cards-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #333;
  }

  .cards-description {
    font-size: 12px;
    line-height: 1.8 !important;
    color: #666;
  }

  /* 卡片交互状态 */
  .cards.active {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
  }
  /* <!-- 应用开发区域 --> */
  /* 整体区域样式 */
  .agent-development {
    padding: 20px;
    background-color: #f4f7fc; /* 柔和背景色，提升整体质感 */
    border-radius: 10px;
  }

  /* 标题区域 */
  .expert-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .expert-title p:first-child {
    margin-top: 10px;
    font-size: 20px;
    font-weight: bold;
    color: #2a3f5f;
    margin-bottom: 10px;
  }

  .expert-title .sys {
    font-size: 14px;
    color: #607d99;
    line-height: 1.6 !important;
    padding: 0 10px;
  }

  /* 模块容器 */
  .conbox {
    display: flex;
    flex-direction: column; /* 垂直排列 */
    gap: 25px; /* 模块间距更大，增强分割感 */
  }

  .contond {
    width: 80vw !important; /* 宽度占满容器 */
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; /* 内部间距适当放大 */
  }

  /* 图片及文字描述 */
  .image-item1,
  .image-item2,
  .image-item3 {
    width: 50vw !important; /* 宽度占满容器 */
    line-height: 7.25vw !important; /* 行高适应文字 */
    text-align: center;
    padding: 10px 15px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  }

  .image-text {
    text-align: center !important;
    line-height: 1.4 !important;
    font-size: 18px;
    font-weight: 600;
    color: #1e2a38;
    margin-bottom: 8px;
  }

  .image-text1 {
    font-size: 14px;
    color: #4a5a6a;
    line-height: 1.8 !important;
    padding: 0 10px;
  }

  /* 卡片内容样式 */
  .pro-item {
    width: 100% !important; /* 卡片宽度占满容器 */
  }

  .xian {
    height: 2px;
    width: 95% !important;
    background-color: #cbd6e1;
    margin: 15px auto; /* 居中对齐 */
    border-radius: 1px;
  }

  .card-pro {
    display: flex;
    flex-direction: column; /* 垂直排列 */
    gap: 20px;
    align-items: center;
  }

  /* 卡片样式 */
  .feature {
    width: 90%; /* 卡片宽度适配屏幕 */
    padding: 15px 10px;
    background: linear-gradient(145deg, #ffffff, #e6ebf3);
    border-radius: 12px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.12);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  .feature:hover {
    transform: scale(1.05); /* 鼠标悬浮放大 */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
  }

  .feature-image {
    width: 20px !important;
    height: 20px !important;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 15px;
    border: 3px solid #e3e8f0; /* 增加圆形边框 */
  }
  .come {
    text-align: center !important;
    /* width: 100px !important; */
    line-height: 1.2 !important;
  }
  .feature-title {
    line-height: 1.4 !important;
    font-size: 16px;
    font-weight: bold;
    color: #2a3f5f;
    margin-bottom: 8px;
  }

  .feature-description {
    font-size: 13px;
    color: #55677c;
    line-height: 1.5 !important;
  }

  /* 底部标题 */
  .agent-title {
    text-align: center;
    margin: 30px 0;
  }

  .agent-title p {
    font-size: 18px;
    font-weight: bold;
    color: #2a3f5f;
  }

  /* 底部导航 */
  .agent-end {
    margin-top: 20px;
    padding: 10px 0 10px 0 !important;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.08);
  }

  .nav-container {
    width: 100%;
    text-align: center;
  }

  .nav-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap; /* 自动换行 */
    justify-content: center;
    gap: 12px;
  }

  .nav-item {
    padding: 10px 15px;
    background-color: #f5f7fa;
    border-radius: 8px;
    font-size: 14px;
    color: #2a3f5f;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .nav-item.active {
    background-color: #007bff; /* 高亮背景色 */
    color: #ffffff;
  }

  .nav-item:hover {
    background-color: #e3e8f0;
  }

  .nav-link {
    text-decoration: none;
    color: inherit;
    font-size: 14px;
  }

  /* <!-- 模型调优区域 --> */
  /* 调整标题字体大小 */
  .deploy-title p {
    width: 140px !important;
    line-height: 1.4 !important;
    font-size: 1.2rem; /* 确保标题适应小屏幕 */
    text-align: center;
    margin-bottom: 20px;
  }

  /* 部署服务内容部分 */
  .deploy-center {
    padding: 0 10px;
  }

  /* 调整部署项的布局：单列显示 */
  .deployment-container {
    display: block; /* 将原来的 flex 布局改为单列 */
    margin-top: 20px;
  }

  /* 每个部署项占满屏幕 */
  .deployment-item {
    width: 100%; /* 部署项占据整行 */
    margin-bottom: 20px; /* 增加底部间距 */
    padding: 15px;
    box-sizing: border-box;
  }

  /* 每个部署项的标题调整 */
  .content h3 {
    line-height: 1.4 !important;
    font-size: 1.1rem;
    margin-bottom: 10px;
    text-align: center;
  }

  /* 调整描述文本字体大小 */
  .content p {
    font-size: 0.9rem; /* 确保描述文字更适合小屏幕 */
    line-height: 1.6 !important;
    color: #555;
    margin-bottom: 12px;
  }

  /* 图标与文字的布局调整 */
  .cen-top {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
  }

  .cen-top img {
    width: 30px; /* 图标调整大小 */
    height: 30px;
    margin-right: 10px;
  }

  .nun {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
  }

  .sepa {
    font-size: 0.8rem;
    color: #777;
    text-align: center; /* 让描述文本在小屏幕中居中 */
  }

  /* 按钮全宽显示 */
  .contact button {
    width: 100%; /* 按钮全宽 */
    padding: 12px 20px;
    font-size: 1rem;
    text-align: center;
    margin-top: 20px;
  }

  .contact button:hover {
    background-color: #0056b3; /* 按钮悬浮时的背景色 */
  }

  /* 调整每个部署项的间距 */
  .deployment-item {
    margin: 0 0 15px 0;
    padding: 15px;
  }

  /* AI */
  /* 整体布局部分 */
  section.lication {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px; /* 适当内边距，可按需调整 */
    box-sizing: border-box;
  }

  /* lication-contont 部分样式 */
  .lication-contont {
    padding: 10px !important;
    width: 100%;
  }

  /* lica-conter 内部布局样式 */
  .lica-conter {
    display: flex;
    flex-direction: column; /* 移动端先设置为列布局，方便查看 */
    align-items: center;
  }

  /* lication-left 文本部分样式 */
  .lication-left {
    text-align: center; /* 文本居中对齐 */
    padding: 10px !important; /* 适当内边距 */
  }
  .lication-left p {
    width: 60.041667vw !important;
    margin: 5px 0; /* 段落间距 */
    font-size: 14px; /* 字体大小，可调整 */
    line-height: 1.5 !important; /* 行高 */
  }
  .lication-left.excel {
    font-size: 12px; /* 可和普通段落字体大小区分开 */
  }

  /* lication-right 图片部分样式 */
  .location-right img {
    width: 100%; /* 图片占满父元素宽度 */
    max-width: 300px; /* 限制最大宽度，避免图片过大 */
    height: auto; /* 保持图片比例 */
    margin: 10px 0; /* 上下间距 */
  }

  /* lication-center 样式 */
  .lication-center {
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .lication-center img {
    width: 30px; /* 装饰图片宽度，可调整 */
    height: auto;
  }
  .lication-center p {
    font-size: 16px;
    font-weight: bold;
    margin: 0 10px;
  }

  /* location-down 部分样式 */
  .location-down {
    width: 100%;
    height: 50px;
  }

  /* mylist 列表样式 */
  .mylist {
    height: 130% !important;
    display: flex;
    overflow-x: auto; /* 水平滚动 */
    -webkit-overflow-scrolling: touch; /* 移动端滚动效果优化 */
    scroll-snap-type: x mandatory; /* 滚动对齐效果 */
    padding: 5px 0;
  }

  .mylist .list-item img {
    width: 7.125vw !important;
    height: 7.125vw !important;
  }

  .mylist.list-item {
    flex: 0 0 auto;
    width: 100px; /* 每个列表项宽度，可调整 */
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .mylist.list-item img {
    width: 80px; /* 图片宽度，可调整 */
    height: 80px; /* 图片高度，可调整 */
    border-radius: 50%; /* 图片设为圆形，可按需更改 */
    margin-bottom: 5px;
  }
  .mylist.list-item p {
    font-size: 12px;
  }

  /* 打造高智商 */
  /* 整体的clever类容器样式 */
  .clever {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 10px; /* 适当内边距，可按需调整 */
  }

  /* clever-title部分样式 */
  .clever-title {
    text-align: center; /* 标题部分文本居中对齐 */
  }
  .clever-title p {
    margin: 5px 0; /* 段落上下间距 */
    font-size: 16px; /* 字体大小，可按需更改 */
    line-height: 1.5 !important; /* 行高设置，让文本更易读 */
  }
  .clever-title.sys {
    font-size: 14px; /* 描述性文字字体稍小些 */
  }

  /* button-list部分，包含按钮列表的样式 */
  .button-list {
    display: flex;
    flex-wrap: wrap; /* 按钮过多时换行显示 */
    justify-content: center; /* 按钮在水平方向居中排列 */
    /* margin: 10px 0; */
  }

  /* 单个button-item样式，即每个按钮的样式 */
  .button-item {
    width: 80px; /* 按钮宽度，可根据实际情况调整 */
    height: 80px; /* 按钮高度 */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    cursor: pointer; /* 鼠标悬停变为手型，提示可点击 */
    position: relative; /* 为了后面可能的伪元素等定位做准备 */
  }
  .button-item.active {
    /* 激活状态按钮的样式，可自定义突出显示效果 */
    border: 2px solid #007bff; /* 示例边框样式，可换颜色等 */
    border-radius: 10px; /* 圆角效果 */
  }
  .button-item.button-image {
    width: 50px; /* 按钮内图片宽度 */
    height: 50px; /* 按钮内图片高度 */
    margin-bottom: 5px;
  }
  .button-item.button-label {
    font-size: 12px; /* 按钮文字大小 */
    text-align: center;
  }

  /* clever-center部分样式，包含左右两部分布局 */
  .clever-center {
    width: 100%;
    display: flex;
    flex-direction: column; /* 移动端先设置为列布局，方便查看和适配 */
    align-items: center;
  }

  /* clever-left部分样式 */
  .clever-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .clever-left.card-pro {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .clever-left.card-pro.come {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .clever-left.card-pro.feature {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
  .clever-left.card-pro.feature-image {
    width: 40px; /* 特征图片宽度，可调整 */
    height: 40px; /* 特征图片高度，可调整 */
    margin-right: 10px;
  }
  .clever-left.card-pro.feature-title {
    font-size: 14px;
  }
  .clever-left.testmore {
    margin-top: 10px;
  }
  .clever-left.testmore button {
    padding: 8px 15px; /* 按钮内边距 */
    font-size: 14px;
    background-color: #007bff; /* 按钮背景色，可改 */
    color: white; /* 按钮文字颜色 */
    border: none;
    border-radius: 5px; /* 按钮圆角 */
  }

  /* clever-right部分样式，根据条件显示的图片相关 */
  .clever-right {
    width: 100%;
    display: flex;
    justify-content: center; /* 图片在水平居中显示 */
    margin: 10px 0;
  }
  .clever-right.main-image,
  .clever-right.overlay-image {
    width: 100%;
    max-width: 300px; /* 限制图片最大宽度，避免过大 */
    height: auto;
  }
  .clever-right.overlay-image {
    position: absolute; /* 覆盖图片的定位，可根据实际效果调整 */
    top: 0;
    left: 0;
    opacity: 0; /* 初始隐藏，可通过交互显示 */
    transition: opacity 0.3s ease; /* 过渡效果 */
  }
  .clever-right:hover.overlay-image {
    opacity: 0.5; /* 鼠标悬停时显示一定透明度 */
  }
  /* tab切换 */
  /* 整体内容区域 */
  .clever-content {
    display: flex;
    flex-direction: row; /* 垂直排列按钮和内容 */
    padding: 20px;
    gap: 30px; /* 添加上下间距 */
  }

  /* 按钮列表 */
  .button-list {
    width: 25vw !important;
    display: flex;
    background: none !important;
    flex-direction: column; /* 垂直排列按钮 */
    align-items: center; /* 按钮中央对齐 */
    gap: 20px;
  }

  .button-item {
    width: 100%; /* 按钮项宽度 */
    max-width: 300px; /* 限制最大宽度 */
    padding: 10px;
    background-color: #f0f0f0; /* 按钮背景色 */
    border-radius: 8px; /* 圆角 */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transition: all 0.3s ease; /* 添加过渡效果 */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* 微弱阴影 */
  }

  .button-item:hover {
    background-color: #007bff; /* 悬停时背景色变化 */
    color: white;
    transform: scale(1.05); /* 悬停时放大效果 */
  }

  .button-image {
    width: 4.9375vw !important; /* 按钮图片最大宽度 */
    height: 4.9375vw !important;
    margin-right: 10px;
    margin-bottom: 10px;
  }

  .button-label {
    font-size: 16px; /* 调整按钮文字大小 */
    font-weight: 500;
    letter-spacing: 0.5px;
    color: #333; /* 默认文字颜色 */
    text-transform: uppercase; /* 文字大写 */
  }

  /* 内容区域 */
  .clever-center {
    width: 100%; /* 内容区域宽度全屏 */
    display: flex;
    flex-direction: column; /* 垂直排列内容 */
    align-items: center; /* 内容中央对齐 */
    gap: 30px; /* 内容之间的间距 */
  }

  .clever-left {
    width: 50vw !important;
    text-align: center;
  }

  .card-pro {
    padding: 0 !important;
    margin-bottom: 30px;
  }

  .feature {
    margin-bottom: 20px;
    text-align: center;
  }

  .feature-image {
    max-width: 100%;
    height: auto;
    border-radius: 8px; /* 卡片图片圆角 */
  }

  .feature-title {
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
    color: #333; /* 标题颜色 */
  }

  .testmore button {
    background-color: #007bff; /* 按钮背景色 */
    color: white;
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 20px;
  }

  .testmore button:hover {
    background-color: #0056b3; /* 悬停时背景色 */
  }

  .come {
    font-size: 20px;
    font-weight: 600;
    color: #333; /* 文本颜色 */
  }

  /* 右侧显示区域 */
  .clever-right {
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }

  .main-image,
  .overlay-image {
    max-width: 80%;
    height: auto;
    border-radius: 8px; /* 图片圆角 */
  }

  /* 按钮的active状态样式 */
  .button-item.active {
    background-color: #007bff; /* Active状态背景 */
    color: white;
  }

  /* <!-- 底部区域 --> */

  /* Footer 基础样式 */
  footer.footer {
    padding: 10px;
    background-color: #333; /* 深色背景 */
    color: #fff;
  }

  .foot {
    display: block; /* 使用块级布局，变为单列 */
    margin-bottom: 20px;
    padding: 10px 15px 0 15px !important;
  }

  .left,
  .right {
    margin-bottom: 20px; /* 每个部分之间的间距 */
    text-align: center; /* 使内容居中对齐 */
  }

  /* 标题文字样式 */
  .left p,
  .right p {
    font-size: 1.4rem; /* 更大的字体 */
    font-weight: bold;
    margin-bottom: 15px;
    color: #fff;
  }

  /* 联系方式内容样式 */
  .footer-content p,
  .footer-contant p {
    font-size: 1rem; /* 适中的字体 */
    color: #ccc;
    line-height: 1.5 !important;
    margin: 8px 0;
  }

  /* 二维码图片样式 */
  .footer-contant img {
    width: 80px !important; /* 缩小二维码图标 */
    height: 80px !important;
    margin-top: 10px;
  }

  /* 底部分割线 */
  .xian {
    border-top: 1px solid #444;
    margin: 20px 0;
  }

  /* 版权信息样式 */
  .pany {
    font-size: 0.9rem;
    text-align: center;
    color: #bbb;
    margin-top: 15px;
    line-height: 1.3 !important;
  }

  /* 增加间距和对齐，提升可读性 */
  .footer-content,
  .footer-contant {
    /* padding: 0 20px; 在左右两侧增加一些内边距 */
  }
}
</style>
<!-- PC -->
<style>
#app {
  /* background-color: red; */
}
body {
  margin: 0;
  padding: 0;
}
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 8px;
  background: #036775;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #4ef096;
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
/* 全局样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  color: #333;
}

h1,
h2 {
  text-align: center;
  margin-bottom: 20px;
}

p {
  text-align: center;
}
/* 放大状态样式 */
.enlarged {
  position: fixed; /* 固定定位在窗口中 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(3); /* 中心定位并放大 */
  z-index: 1000; /* 确保图片位于最上层 */
  background: white; /* 可选：图片背景避免干扰 */
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* 可选：增加阴影效果 */
}
.fixed-contact {
  position: fixed;
  right: 0;
  top: 610px;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: #ffffff; */
  padding: 0 20px;
  z-index: 1000; /* 确保在页面内容上方 */
}

.contact-card {
  position: fixed;
  right: 87px;
  top: 600px;
  z-index: 99999;
  width: 265px;
  height: 310px;
  background: url("./assets/img/背景@2x.png") 0 0 / cover no-repeat;
  padding: 29px 42px 30px 43px;
  box-sizing: border-box;
  overflow: hidden;
  img {
    margin: 15px 15px 15px 20px;
    width: 150px;
    height: 150px;
  }
}
.contact-phone {
  margin-top: 10%;
  width: 200px;
  height: 80px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 12px;
  .phonename {
    font-family: AlibabaPuHuiTiM;
    font-size: 20px;
    color: #1d2129;
    line-height: 25px;
    font-style: normal;
  }
  .phonenum {
    font-family: AlibabaPuHuiTiR;
    font-size: 16px;
    color: rgba(29, 33, 41, 0.8);
    line-height: 40px;
    font-style: normal;
    font-weight: 600;
  }
}

.jingli {
  margin-left: 10px;
  font-family: AlibabaPuHuiTiM;
  font-size: 20px;
  color: #1d2129;
  line-height: 27px;
  font-style: normal;
}

.fangan {
  font-family: AlibabaPuHuiTiR;
  font-size: 16px;
  margin-left: 9px;
  color: #1d2129;
  line-height: 35px;
  font-style: normal;
}
.qr-code {
  width: 74px;
  height: 74px;
  border-radius: 8px;
}
.contact-actions {
  width: 74px;
  height: 270px;
}
.hezuo {
  width: 74px;
  position: fixed;
  margin-top: -22px;
  background: #006dff;
  box-shadow: 0px 0px 20px 0px rgba(57, 105, 168, 0.24);
  cursor: pointer;
  border-radius: 4px;
  z-index: 1000;
  .hezuozi {
    width: 100%;
    display: flex;
    /* padding: 3px 9px; */
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #ffffff;
    line-height: 20px;
    text-align: right;
    font-style: normal;
    z-index: 9999;
    justify-content: center;
  }
}
.contact-actions img {
  width: 74px;
  height: 74px;
  /* margin-top: 15px; */
  cursor: pointer;
}

.contact-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  width: 40px;
  height: 115px;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin: 12px 0 0 18px;
}
.option {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 12px;
}

.option img {
  width: 20px;
  height: 20px;
  margin-left: 4px;
}

.option p {
  font-size: 14px;
  color: #333;
  margin: 0;
}
.optiond {
  position: fixed;
  right: 36px;
  width: 40px;
  height: 40px;
  background: #ffffff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-top: 22vh;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* img {
    width: 20px;
    height: 20px;
  } */
}
.optiond img {
  width: 20px;
  height: 20px;
  margin-top: 12px;
}

.optiond:hover img {
  transform: scale(1.1); /* 鼠标悬停时图标稍微放大 */
  transition: transform 0.2s ease;
}
.option:hover p {
  color: #007bff; /* 鼠标悬停时文字变蓝色 */
}

.option:hover img {
  transform: scale(1.1); /* 鼠标悬停时图标稍微放大 */
  transition: transform 0.2s ease;
}

.header {
  padding: 163px 924px 163px 360px; /* 初始的 padding */
  background: rgba(0, 0, 0, 0.6) url("./assets/img/首页banner\ @2x.png") 0 0 /
    cover no-repeat;
  p {
    font-family: AlibabaPuHuiTiR;
    font-size: 17px;
    color: #1d2129;
    line-height: 25px;
    text-align: left;
    font-style: normal;
  }
}
.header-descr {
  padding: 0 203px 17px 0;
  p {
    font-family: AlibabaPuHuiTiM;
    font-size: 37px;
    color: #1d2129;
    line-height: 66px;
    text-align: justify;
    font-style: normal;
  }
}
.expert-models {
  background: #ffffff;
}
.expert-content {
  padding: 60px 360px;
}

.expert-title {
  /* padding: 0 241px 532px 242px; */
  p {
    font-family: AlibabaPuHuiTiM;
    font-size: 28px;
    color: #1d2129;
    line-height: 38px;
    text-align: center;
    font-style: normal;
  }
  .sys {
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #646a73;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    padding: 16px 0 0 0;
  }
}
/* 卡片容器样式 */
.card-container {
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px 0 24px 0;
}

/* 激活状态的卡片样式 */
.card.active {
  background: linear-gradient(
    267deg,
    #00a2ff 0%,
    #006cff 100%
  ); /* 深蓝背景色 */
  color: white; /* 字体颜色变为白色 */
  transform: translateY(-2px); /* 鼠标移入时上浮效果 */
}

.card {
  position: relative; /* 为了将图片定位到右上角 */
}

.card-image {
  position: absolute;
  right: 10px;
  top: 20px;
  width: 120px; /* 根据需要调整图片大小 */
  height: 120px; /* 根据需要调整图片大小 */
  object-fit: cover; /* 保持图片的宽高比 */
  /* z-index: 999; */
}

/* 卡片标题样式 */
.card-title {
  padding: 31px 0 0 24px;
  font-size: 18px;
  font-weight: bold;
}

/* 卡片选项导航样式 */
.card-tabs {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 12px 0 0 24px;
  /* margin: 0 0 15px; */
}

.tab {
  background: #ffffff;
  border: 1px solid #d1e9ff;
  border-radius: 6px;
  padding: 4px 12px;
  font-size: 14px;
  color: #646a73;
  transition: background-color 0.3s ease;
  cursor: pointer;
  z-index: 999;
}
.tab:hover {
  background: rgba(255, 255, 255, 0.95);
  transition: background-color 0.3s ease;
}

/* 按钮按下时的样式 */
.tab:active {
  transform: scale(0.98); /* 按钮缩小效果 */
}

.card.active .card-description {
  /* background: #40a9ff; */
  color: #ffffff;
}

/* 卡片描述文字样式*/
.card-description {
  padding: 30px 24px 31px 24px;
  font-family: AlibabaPuHuiTiR;
  font-size: 16px;
  color: #1d2129;
  line-height: 26px;
  text-align: justify;
  font-style: normal;
  position: relative;
  z-index: 9999999;
}

/* 卡片描述文字样式 */
.cards-description {
  line-height: 5.354167vw;
  padding: 30px 24px 31px 24px;
  font-family: AlibabaPuHuiTiR;
  font-size: 16px !important;
  color: #1d2129 !important;
  line-height: 26px;
  text-align: justify;
  font-style: normal;
}
.cards.active .cards-description {
  /* background: #40a9ff; */
  color: #ffffff !important;
}
/* 卡片容器样式 */
.card-coner {
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
  margin: 24px 0 40px 0;
}

/* 激活状态的卡片样式 */
.cards.active {
  background: linear-gradient(
    267deg,
    #00a2ff 0%,
    #006cff 100%
  ); /* 深蓝背景色 */
  color: white; /* 字体颜色变为白色 */
  transform: translateY(-2px); /* 鼠标移入时上浮效果 */
}

.cards {
  position: relative; /* 为了将图片定位到右上角 */
}

.cards-image {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 120px; /* 根据需要调整图片大小 */
  height: 120px; /* 根据需要调整图片大小 */
  object-fit: cover; /* 保持图片的宽高比 */
  /* z-index: 999; */
}

/* 卡片标题样式 */
.cards-title {
  padding: 31px 0 0 24px;
  font-size: 18px;
  font-weight: bold;
}

/* 卡片选项导航样式 */
.cards-tabs {
  display: flex;
  gap: 10px;
  list-style: none;
  padding: 12px 0 0 24px;
  /* margin: 0 0 15px; */
}

.tabs {
  background: #ffffff;
  border: 1px solid #d1e9ff;
  border-radius: 6px;
  padding: 5px 10px;
  font-size: 14px;
  color: #646a73;
  transition: background-color 0.3s ease;
  cursor: pointer;
  z-index: 999;
}

.tabs:active {
  transform: scale(0.98); /* 按钮缩小效果 */
}

.tab:hover {
  background: rgba(255, 255, 255, 0.95);
  transition: background-color 0.3s ease;
}

.more {
  /* padding: 0 515px 0px 515px; */
  text-align: center;
  button {
    background: linear-gradient(267deg, #00a2ff 0%, #006cff 100%); /* 背景色 */
    color: white; /* 文字颜色 */
    border: none; /* 移除默认的边框 */
    padding: 13px 20px; /* 内边距 */
    font-size: 16px; /* 字体大小 */
    cursor: pointer; /* 鼠标悬停时显示手型 */
    border-radius: 4px; /* 圆角 */
    transition: background-color 0.3s ease; /* 背景色过渡效果 */
  }
  /* 鼠标悬停时的样式 */
  button:hover {
    background: linear-gradient(
      267deg,
      rgba(0, 162, 255, 0.8) 0%,
      rgba(0, 108, 255, 0.8) 100%
    ); /* 悬停时改变背景色 */
  }

  /* 按钮按下时的样式 */
  button:active {
    background-color: #3e8e41; /* 按下时改变背景色 */
    transform: scale(0.98); /* 按钮缩小效果 */
  }
}
.model-services {
  /* margin-top: 20px; */
  background: rgba(0, 0, 0, 0.6) url("./assets/img/模型调优背景@2x.png") 0 0 /
    cover no-repeat;
}

.services-title {
  padding: 60px 590px 56px 590px;
  p {
    font-family: AlibabaPuHuiTiM;
    font-size: 28px;
    color: #1d2129;
    line-height: 38px;
    text-align: center;
    font-style: normal;
  }
  .sys {
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #646a73;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    padding: 16px 0 0 0;
  }
}
.services-content {
  padding: 0 328px 60px 329px;
  display: flex;
  justify-content: center;
  .left {
    width: 860px;
    height: 832px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    padding-left: 60px;
    .card-coner {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }

    /* 激活状态的卡片样式 */
    .cards.active {
      background: linear-gradient(
        267deg,
        #00a2ff 0%,
        #006cff 100%
      ); /* 深蓝背景色 */
      color: white; /* 字体颜色变为白色 */
      transform: translateY(-2px); /* 鼠标移入时上浮效果 */
    }

    .cards {
      position: relative; /* 为了将图片定位到右上角 */
    }

    .cards-image {
      position: absolute;
      left: 24px;
      top: 36px;
      width: 20px; /* 根据需要调整图片大小 */
      height: 20px; /* 根据需要调整图片大小 */
      object-fit: cover; /* 保持图片的宽高比 */
      /* z-index: 999; */
    }

    /* 卡片标题样式 */
    .cards-title {
      padding: 34px 0 0 59px;
      font-size: 18px;
      font-weight: bold;
    }

    .cards.active .cards-description {
      /* background: #40a9ff; */
      color: #ffffff;
    }

    /* 卡片描述文字样式 */
    .cards-description {
      padding: 12px 24px 21px 60px;
      font-family: AlibabaPuHuiTiR;
      font-size: 14px;
      color: #646a73;
      line-height: 1.6;
      text-align: justify;
      font-style: normal;
    }
    .cards {
      background: rgba(246, 249, 255, 1);
      padding: 0;
      width: 343px;
      box-shadow: 0px 2px 16px 0px rgba(205, 212, 221, 0.6);
      border-radius: 4px;
      /* box-shadow: 0px 4px 20px 0px #BECEDF; */
    }
  }
}
.agent-development {
  background: #ffffff;
  .conbox {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    .contond {
      width: 384px;
      /* background: #ffffff; */
      box-shadow: 0px 2px 16px 0px rgba(205, 212, 221, 0.42);
      border-radius: 4px;
      border: 1px solid #ffffff;
      margin-right: 24px;
      padding: 0 0 18px 0;
      /* 背景图片设置 */
      .image-item1 {
        width: 384px; /* 设置图片的宽度 */
        background-size: cover; /* 背景图片按比例缩放填满容器 */
        background-position: center; /* 背景图片居中对齐 */
        overflow: hidden; /* 防止内容溢出 */
        background-image: url("./assets/img/01-丰富工具库@2x.png"); /* 设置背景图像 */
        padding: 0 0 17px 0;
      }
      .image-item2 {
        width: 384px; /* 设置图片的宽度 */
        background-size: cover; /* 背景图片按比例缩放填满容器 */
        background-position: center; /* 背景图片居中对齐 */
        overflow: hidden; /* 防止内容溢出 */
        background-image: url("./assets/img/02-企业知识库@2x.png");
        padding: 0 0 17px 0;
      }
      .image-item3 {
        width: 384px; /* 设置图片的宽度 */
        background-size: cover; /* 背景图片按比例缩放填满容器 */
        background-position: center; /* 背景图片居中对齐 */
        overflow: hidden; /* 防止内容溢出 */
        background-image: url("./assets/img/03工作流编排@2x.png");
        padding: 0 0 17px 0;
      }

      /* 文字的样式 */
      .image-text {
        margin: 24px 0 0 24px;
        font-family: AlibabaPuHuiTiM;
        font-size: 20px;
        color: #1d2129;
        line-height: 27px;
        text-align: left;
        font-style: normal;
      }
      .image-text1 {
        margin: 9px 24px 0 24px;
        font-family: AlibabaPuHuiTiR;
        font-size: 14px;
        color: #646a73;
        line-height: 24px;
        text-align: justify;
        font-style: normal;
      }
      .pro-item {
        width: 384px; /* 设置图片的宽度 */
        .xian {
          width: 337px;
          margin-left: 24px;
          height: 1px;
          background: rgba(151, 151, 151, 0.2);
        }

        .card-pro {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          padding: 32px 24px 0 24px;
        }

        .feature {
          position: relative; /* 为了将图片定位到右上角 */
        }

        .feature-image {
          position: absolute;
          left: 26px;
          top: 5px;
          width: 17px; /* 根据需要调整图片大小 */
          height: 17px; /* 根据需要调整图片大小 */
          object-fit: cover; /* 保持图片的宽高比 */
          /* z-index: 999; */
        }

        /* 卡片标题样式 */
        .feature-title {
          padding: 0 0 0 53px;
          font-size: 18px;
          font-weight: bold;
        }
        /* 卡片描述文字样式 */
        .feature-description {
          padding: 4px 0 30px 53px;
          font-family: AlibabaPuHuiTiR;
          font-size: 14px;
          color: #646a73;
          line-height: 20px;
          text-align: justify;
          font-style: normal;
        }
        .feature,
        .dev-feature {
          background: #ffffff;
          /* padding: 31px 24px; */
          /* width: 343px; */
          /* box-shadow: 0px 2px 16px 0px rgba(205, 212, 221, 0.6);
          border-radius: 4px; */
          /* box-shadow: 0px 4px 20px 0px #BECEDF; */
        }
      }
    }
  }
}
.lication {
  background: rgba(0, 0, 0, 1) url("./assets/img/工作流就是生产线背景图@2x.png")
    0 0 / cover no-repeat;
}
.lication-contont {
  padding: 60px 360px 44px 360px;
  display: flex;
  justify-content: center;
  /* background: red; */
  /* box-shadow: 0px 2px 40px 0px rgba(205, 212, 221, 0.3); */
  border-radius: 12px;
  border-top: 1px solid #ffffff;
  .lica-conter {
    width: 100%;
    height: 100%;
    background: #f9fbff;
    box-shadow: 0px 2px 40px 0px rgba(205, 212, 221, 0.3);
    border-radius: 12px;
    border: 1px solid #ffffff;
    display: flex;
    justify-content: center;
    .lication-left {
      padding: 106px 124px 0px 36px;
      p {
        /* padding: 0 0 0px 0px; */
        width: 500px;
        font-family: AlibabaPuHuiTiM;
        font-size: 22px;
        color: #1d2129;
        line-height: 30px;
        text-align: justify;
        font-style: normal;
      }
      .excel {
        width: 500px;
        padding-top: 40px;
        font-family: AlibabaPuHuiTiR;
        font-size: 16px;
        color: #646a73;
        line-height: 24px;
        text-align: left;
        font-style: normal;
      }
    }
    .location-right {
      padding: 34px 20px 33px 0;
      img {
        width: 520px;
        height: 293px;
      }
    }
  }
}
.lication-center {
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    padding: 0 24px 0 24px;
    font-family: AlibabaPuHuiTiM;
    font-size: 18px;
    color: #1d2129;
    line-height: 25px;
    text-align: right;
    font-style: normal;
  }
  img {
    width: 210px;
    height: 11px;
  }
}

/* 定义变量 */
:root {
  /* 元素高度 */
  --list-item-height: 94px;
  /* 元素宽度 */
  --list-item-width: 112px;
  /* 元素数量 */
  --list-item-number: 9;
  --duration: 16;
}
@keyframes scrolling {
  to {
    transform: translateX(calc(var(--list-item-width) * -1));
  }
}
.scroll-gallery {
  height: 110px !important;
}
.location-down {
  padding: 40px 145px 61px 145px;
  border-bottom: 1px solid #ffffff;
  .image-gallery {
    /* display: flex;
    justify-content: space-around;
    align-items: center; */
    display: flex;
    /* height: 100px;
    width: 100%; */
    text-wrap: nowrap;
    overflow: hidden;
    height: var(--list-item-height);
    width: 80vw;
    position: relative;
    mask-image: linear-gradient(
      90deg,
      hsl(0 0% 0% / 0),
      hsl(0 0% 0% / 1) 20%,
      hsl(0 0% 0% / 1) 80%,
      hsl(0 0% 0% / 0)
    );
  }

  .image-item {
    text-align: center;
    flex-shrink: 0; /* 防止项目被压缩 */
    margin: 0 0 0 70px;

    float: left;
    /* animation: scroll-left 2s linear infinite; */
    /* transform: translateX(
      calc(var(--list-item-width) * var(--list-item-number))
    ); */
    height: var(--list-item-height);
    width: var(--list-item-width);
    /* background-image: url(./1.jfif); */
    background-size: cover;
    /* animation: scrolling var(--duration) linear infinite; */
    cursor: pointer;
  }

  .image-item img {
    width: 60px; /* 图片宽度自适应 */
    height: 60px; /* 图片等比例缩放 */
    border-radius: 10px; /* 圆角 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 图片阴影 */
    transition: transform 0.2s ease; /* 鼠标悬停时的动画效果 */
    cursor: pointer;
  }

  .image-item img:hover {
    transform: scale(1.1); /* 鼠标悬停放大 */
  }
  /* 按钮按下时的样式 */
  .image-item img:active {
    transform: scale(0.98); /* 按钮缩小效果 */
  }

  .image-item p {
    margin-top: 10px;
    font-size: 14px;
    color: #1d2129;
    font-family: "AlibabaPuHuiTiR", sans-serif;
  }

  .image-gallery:has(.image-item:hover) .image-item {
    animation-play-state: paused;
  }
}
/* 定义滚动的动画 */
/* @keyframes scroll-left {
  0% {
    transform: translateX(100px);
  }
  50% {
    transform: translateX(50px);
  }

  100% {
    transform: translateX(100px);
  }
} */

.agent-title {
  padding-top: 30px;
  p {
    font-family: AlibabaPuHuiTiM;
    font-size: 28px;
    color: #1d2129;
    line-height: 38px;
    text-align: center;
    font-style: normal;
  }
}

.clever-title {
  padding: 60px 416px 40px 417px;
  p {
    font-family: AlibabaPuHuiTiM;
    font-size: 28px;
    color: #1d2129;
    line-height: 38px;
    text-align: center;
    font-style: normal;
  }
  .sys {
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #646a73;
    line-height: 20px;
    text-align: center;
    font-style: normal;
    padding: 16px 0 0 0;
  }
}

.clever-content {
  padding: 0px 360px 60px 360px;
  display: flex;
  justify-content: center;
  .button-list {
    display: flex;
    flex-direction: column;
    background: #f0f7fe;
    border-radius: 8px 0px 0px 8px;
    justify-content: space-around;
  }

  .button-item {
    display: flex;
    align-items: center;
    padding: 8px;
    background-color: transparent;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative; /* 确保 transform 的应用不影响其他元素 */
  }

  .button-item.active {
    padding: 8px;
    background: linear-gradient(267deg, #00a2ff 0%, #006cff 100%);
    /* border-radius: 2px; */
  }

  .button-image {
    width: 18px;
    height: 18px;
    margin-left: 8px;
    /* transition: background-color 0.3s; */
  }

  .button-item.active .button-image {
    /* background: linear-gradient(267deg, #00a2ff 0%, #006cff 100%); */
    border-radius: 2px;
  }

  .button-label {
    padding: 13px 13px 13px 9px;
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #1d2129;
    line-height: 10px;
    text-align: left;
    font-style: normal;
    transition: color 0.3s;
  }

  .button-item.active .button-label {
    padding: 13px 13px 13px 9px;
    font-family: AlibabaPuHuiTiR;
    font-size: 14px;
    color: #ffffff;
    line-height: 10px;
    text-align: left;
    font-style: normal;
  }
  .button-item:active {
    transform: translateY(2px); /* 按钮整体下压 */
  }

  .button-item:active .button-image,
  .button-item:active .button-label {
    transform: translateY(2px); /* 图片和文字下压 */
  }
  .clever-center {
    background: #ffffff;
    box-shadow: 0px 2px 40px 0px rgba(205, 212, 221, 0.53);
    border-radius: 8px;
    display: flex;
    /* border: 1px solid #FFFFFF; */
    .clever-left {
      width: 480px;
      padding: 44px 0 43px 0;
      .card-pro {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        padding: 0 0 0 34px;
      }
      .come {
        font-family: AlibabaPuHuiTiM;
        font-size: 20px;
        color: #1d2129;
        line-height: 27px;
        text-align: left;
        font-style: normal;
      }
      .testmore {
        padding: 30px 0 0 34px;
        button {
          background: linear-gradient(
            267deg,
            #00a2ff 0%,
            #006cff 100%
          ); /* 背景色 */
          color: white; /* 文字颜色 */
          border: none; /* 移除默认的边框 */
          padding: 13px 22px; /* 内边距 */
          font-size: 16px; /* 字体大小 */
          cursor: pointer; /* 鼠标悬停时显示手型 */
          border-radius: 4px; /* 圆角 */
          transition: background-color 0.3s ease; /* 背景色过渡效果 */
        }
        /* 鼠标悬停时的样式 */
        button:hover {
          background: linear-gradient(
            267deg,
            rgba(0, 162, 255, 0.8) 0%,
            rgba(0, 108, 255, 0.8) 100%
          ); /* 悬停时改变背景色 */
        }

        /* 按钮按下时的样式 */
        button:active {
          background-color: #3e8e41; /* 按下时改变背景色 */
          transform: scale(0.98); /* 按钮缩小效果 */
        }
      }

      .feature {
        position: relative; /* 为了将图片定位到右上角 */
        margin-top: 30px;
      }

      .feature-image {
        position: absolute;
        left: 0px;
        top: 5px;
        width: 17px; /* 根据需要调整图片大小 */
        height: 17px; /* 根据需要调整图片大小 */
        object-fit: cover; /* 保持图片的宽高比 */
        /* z-index: 999; */
      }

      /* 卡片标题样式 */
      .feature-title {
        padding: 0 0 0 33px;
        font-family: AlibabaPuHuiTiR;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        text-align: left;
        font-style: normal;
      }
      /* 卡片描述文字样式 */
      /* .feature-description {
        padding: 4px 0 30px 53px;
        font-family: AlibabaPuHuiTiR;
        font-size: 14px;
        color: #646a73;
        line-height: 20px;
        text-align: justify;
        font-style: normal;
      } */
      .feature,
      .dev-feature {
        background: #ffffff;
        /* padding: 31px 24px; */
        /* width: 343px; */
        /* box-shadow: 0px 2px 16px 0px rgba(205, 212, 221, 0.6);
          border-radius: 4px; */
        /* box-shadow: 0px 4px 20px 0px #BECEDF; */
      }
    }
    .clever-right {
      padding: 43px 40px 43px 0;
      position: relative; /* 使子元素可以相对定位 */

      .main-image {
        width: 557px;
        height: 305px;
        object-fit: cover; /* 保持图片比例并填满容器 */
      }

      .overlay-image {
        position: absolute;
        bottom: 28px; /* 垂直居中 */
        right: 103px; /* 水平偏移 */
        width: 150px;
        height: 268px;
        object-fit: contain; /* 确保叠加图片在容器中显示完全 */
        pointer-events: none; /* 禁止点击叠加图片 */
      }
    }
  }
}

.deploy-services {
  background: rgba(0, 0, 0, 0.6)
    url("./assets/img/多种部署方式满足企业要求-背景@2x.png") 0 0 / cover
    no-repeat;
  .deploy-title {
    padding: 60px 794px 0 794px;
    font-family: AlibabaPuHuiTiM;
    font-size: 28px;
    color: #1d2129;
    line-height: 38px;
    text-align: right;
    font-style: normal;
    border: 1px solid #ffffff;
  }
  .deploy-center {
    padding: 40px 383px 60px 337px;

    /* 容器样式 */
    .deployment-container {
      display: flex;
      justify-content: center;
      gap: 24px;
    }

    /* 单个项目的样式 */
    .deployment-item {
      position: relative;
      border-radius: 8px;
      background-size: cover; /* 背景图覆盖整个区域 */
      background-position: center; /* 背景图居中显示 */
    }

    /* 标题样式 */
    h3 {
      margin: 24px 0 0 24px;
      font-family: AlibabaPuHuiTiM;
      font-size: 20px;
      color: #1d2129;
      line-height: 30px;
      text-align: left;
      /* font-style: normal; */
    }
    p {
      padding: 6px 24px 0 24px;
      font-family: AlibabaPuHuiTiR;
      font-size: 14px;
      color: #646a73;
      line-height: 24px;
      text-align: justify;
      font-style: normal;
    }
    .cen-top {
      display: flex;
      align-items: center;
      padding: 24px 0 0 24px;
      img {
        width: 16px;
        height: 16px;
      }
      .nun {
        padding: 0 0 0 6px;
        font-family: AlibabaPuHuiTiM;
        font-size: 16px;
        color: #1d2129;
        line-height: 22px;
        text-align: justify;
        font-style: normal;
      }
    }
    .sepa {
      padding: 0px 24px 0 24px;
      font-family: AlibabaPuHuiTiR;
      font-size: 16px;
      color: #1d2129;
      line-height: 22px;
      text-align: justify;
      font-style: normal;
    }

    .contact {
      position: absolute;
      bottom: 30px;
      right: 127px;
      button {
        background: #ffffff;
        color: #1d2129; /* 文字颜色 */
        border: none; /* 移除默认的边框 */
        padding: 13px 22px; /* 内边距 */
        font-size: 16px; /* 字体大小 */
        line-height: 22px;
        text-align: right;
        font-style: normal;
        cursor: pointer; /* 鼠标悬停时显示手型 */
        border-radius: 4px; /* 圆角 */
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid #ffffff;
        transition: background-color 0.3s ease; /* 背景色过渡效果 */
      }
      /* 鼠标悬停时的样式 */
      button:hover {
        background: rgba(255, 255, 255, 0.8);
      }

      /* 按钮按下时的样式 */
      button:active {
        transform: scale(0.98); /* 按钮缩小效果 */
      }
    }

    /* 背景图片 */
    .private {
      width: 384px;
      height: 470px;
      background-image: url("./assets/img/私有化部署-背景@2x.png"); /* 替换为私有化部署的背景图片路径 */
    }

    .public {
      width: 384px;
      height: 470px;
      background-image: url("./assets/img/公有云部署-背景@2x.png"); /* 替换为公有云部署的背景图片路径 */
    }

    .integrated {
      width: 384px;
      height: 470px;
      background-image: url("./assets/img/软硬一体部署-背景@2x.png"); /* 替换为软硬一体部署的背景图片路径 */
    }
  }
}
.agent-end {
  padding: 40px 0 0 0;
  /* 父容器样式 */
  /* 父容器样式 */
  .nav-container {
    padding: 7px 0;
  }

  /* 导航列表样式 */
  .nav-list {
    display: flex;
    justify-content: space-evenly; /* 均匀分布选项 */
    list-style: none;
    margin: 0;
    padding: 0 414px 0 414px;
    transition: color 0.5s ease; /* 颜色变化平滑且延时 */
    /* border-bottom: 1px solid red; */
  }

  /* 导航项样式 */
  .nav-item {
    position: relative;
  }

  /* 导航链接样式 */
  .nav-link {
    text-decoration: none;
    font-size: 16px;
    line-height: 22px;
    color: #646a73;
    font-weight: 500;
    font-style: normal;
    padding: 5px 10px;
    font-family: AlibabaPuHuiTiR;
    transition: color 0.3s ease; /* 鼠标移入文字颜色渐变 */
    cursor: pointer;
  }

  .nav-link:hover {
    font-size: 16px;
    color: #006dff; /* 鼠标移入时文字颜色 */
  }

  /* 底部蓝色导航条样式 */
  .nav-item::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px; /* 距离文字底部的距离 */
    width: 0;
    height: 2px;
    border-bottom: 2px solid #006dff;
    /* background-color: #006dff;  */
    transition: width 0.2s ease;
  }

  .nav-item:hover::after {
  }

  /* 当前激活选项 */
  .nav-item.active::after {
    width: 100%; /* 激活的选项显示完整导航条 */
  }

  .nav-item.active .nav-link {
    color: #006dff; /* 激活选项文字颜色 */
    font-weight: bold;
  }
}

.expert-cards,
.development-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.card,
.dev-card {
  background: url("./assets/img/背景（短）-默认@2x.png") 0 0 / cover no-repeat;
  /* padding: 31px 24px; */
  width: 384px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 20px 0px #BECEDF; */
}
.cards,
.dev-cards {
  background: url("./assets/img/背景（长）-默认@2x.png") 0 0 / cover no-repeat;
  /* padding: 31px 24px; */
  width: 588px;
  border-radius: 8px;
  /* box-shadow: 0px 4px 20px 0px #BECEDF; */
}

.footer {
  background: #333;
  color: white;
  text-align: center;
  .foot {
    display: flex;
    justify-content: space-evenly;
    margin-right: 35px;
    /* padding: 20px; */
    .left {
      padding: 24px 150px 47px 0;
      p {
        font-family: AlibabaPuHuiTiM;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: justify;
        font-style: normal;
      }
      .footer-content {
        margin-top: 16px;
        p {
          margin-top: 16px;
          font-family: AlibabaPuHuiTiR;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.72);
          line-height: 20px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
    .right {
      padding: 24px 0 23px 0;
      /* margin-right: 30px; */
      p {
        font-family: AlibabaPuHuiTiM;
        font-size: 16px;
        color: #ffffff;
        line-height: 22px;
        text-align: justify;
        font-style: normal;
      }
      .footer-contant {
        margin-top: 10px;
        img {
          width: 92px;
          height: 92px;
        }
        p {
          font-family: AlibabaPuHuiTiR;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.72);
          line-height: 20px;
          text-align: justify;
          font-style: normal;
        }
      }
    }
  }
  .xian {
    border: 1px solid #3b4049;
  }
  .pany {
    padding: 16px 0 16px 0;
    font-family: AlibabaPuHuiTiR;
    font-size: 12px;
    color: #9a9da2;
    line-height: 16px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      cursor: pointer;
      color: #226aff;
    }
  }
}

/* 定义变量 */
:root {
  /* 元素高度 */
  --list-item-height: 110px;
  /* 元素宽度 */
  --list-item-width: 180px;
  /* 元素数量 */
  --list-item-number: 11;
  --duration: 16s;
  overflow-x: hidden;
}

/* body {
        padding: 0;
        margin: 0;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        overflow: hidden;
      } */

@keyframes scrolling {
  to {
    transform: translateX(calc(var(--list-item-width) * -1));
  }
}

.mylist {
  height: var(--list-item-height);
  width: 80vw;
  position: relative;
  margin-left: 5%;
  mask-image: linear-gradient(
    90deg,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 20%,
    hsl(0 0% 0% / 1) 80%,
    hsl(0 0% 0% / 0)
  );
}

.mylist .list-item img {
  width: 60px; /* 图片宽度自适应 */
  height: 60px; /* 图片等比例缩放 */
  border-radius: 10px; /* 圆角 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* 图片阴影 */
  transition: transform 0.2s ease; /* 鼠标悬停时的动画效果 */
  cursor: pointer;
}

.mylist .list-item img:hover {
  transform: scale(1.1); /* 鼠标悬停放大 */
}
/* 按钮按下时的样式 */
.mylist .list-item img:active {
  transform: scale(0.98); /* 按钮缩小效果 */
}

.mylist .list-item p {
  margin-top: 10px;
  font-size: 14px;
  color: #1d2129;
  font-family: "AlibabaPuHuiTiR", sans-serif;
  margin-left: -116px;
}

.mylist .list-item {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(calc(var(--list-item-width) * var(--list-item-number)));
  height: var(--list-item-height);
  width: var(--list-item-width);
  /* background-image: url(./1.jfif); */
  background-size: cover;
  animation: scrolling var(--duration) linear infinite;
  cursor: pointer;
}

.mylist[data-direction="reverse"] .list-item {
  animation-direction: reverse;
  /* background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%); */
}

.mylist .list-item:nth-child(1) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 1) *
      -1
  );
}

.mylist .list-item:nth-child(2) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 2) *
      -1
  );
}

.mylist .list-item:nth-child(3) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 3) *
      -1
  );
}

.mylist .list-item:nth-child(4) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 4) *
      -1
  );
}

.mylist .list-item:nth-child(5) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 5) *
      -1
  );
}

.mylist .list-item:nth-child(6) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 6) *
      -1
  );
}

.mylist .list-item:nth-child(7) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 7) *
      -1
  );
}

.mylist .list-item:nth-child(8) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 8) *
      -1
  );
}

.mylist .list-item:nth-child(9) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 9) *
      -1
  );
}
.mylist .list-item:nth-child(10) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 10) *
      -1
  );
}
.mylist .list-item:nth-child(11) {
  animation-delay: calc(
    (var(--duration) / var(--list-item-number)) * (var(--list-item-number) - 11) *
      -1
  );
}

.mylist:has(.list-item:hover) .list-item {
  animation-play-state: paused;
}

/* 响应式布局 */
@media (max-width: 28px) {
  .expert-cards,
  .development-cards {
    flex-direction: column;
  }

  .card,
  .dev-card {
    width: 90%;
  }

  .header {
    padding: 20px 5px;
  }

  h1,
  h2 {
    font-size: 1.5em;
  }

  .expert-card {
    padding: 15px 20px; /* 减少内边距 */
    max-width: 90%; /* 宽度适应小屏幕 */
  }

  .expert-title {
    font-size: 18px; /* 减小标题字体大小 */
  }

  .tab {
    font-size: 12px; /* 减小选项导航字体大小 */
    padding: 4px 8px; /* 减少内边距 */
  }

  .expert-description {
    font-size: 12px; /* 减小描述字体大小 */
  }
  /* <!-- 打造高智商 --> */
  .clever-title p {
    font-size: 1rem;
    line-height: 1.4;
  }

  .clever-title .sys {
    font-size: 0.9rem;
    color: #666;
  }

  /* 按钮布局调整为一列 */
  .button-list {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .button-item {
    flex: 1 1 80%; /* 按钮占据大部分宽度 */
    margin-bottom: 15px;
    text-align: center;
  }

  .button-item img {
    max-width: 50px;
    transition: transform 0.2s;
  }

  /* 按钮激活状态的效果 */
  .button-item.active img {
    transform: scale(1.1);
  }

  .button-label {
    margin-top: 8px;
    font-size: 0.9rem;
  }

  /* 调整内容区域 */
  .clever-center {
    flex-direction: column;
    margin-top: 20px;
  }

  .clever-left,
  .clever-right {
    flex: 1 1 100%; /* 左右两边区域占据全宽 */
    padding: 10px;
  }

  .card-pro .come {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }

  .feature-title {
    font-size: 1rem;
    margin-top: 8px;
  }

  .testmore button {
    width: 100%; /* 按钮全宽显示 */
    padding: 12px;
    font-size: 1rem;
  }

  /* 图片调整 */
  .feature-image {
    width: 100%;
    height: auto;
  }

  .clever-right img {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
  }
}
</style>
