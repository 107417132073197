import Vue from "vue";
import App from "./App.vue";

import VueScroll from "@david-j/vue-j-scroll";
Vue.use(VueScroll);
Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
}).$mount("#app");
